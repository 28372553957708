import { signOutFirebase } from "./auth/auth";

export const ENDPOINT = "https://backend.fwaygoapp.com";

const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
const redirect_uri = urlParams.get('redirect_uri');

// const signout = urlParams.get('signout');

// if (signout) signOutFirebase();

if(redirect_uri) document.cookie = `targetURL=${redirect_uri}`;
console.log("redirect_uri",redirect_uri);
// export const targetURL = redirect_uri || getCookie("targetURL") ||  "https://distribute.fwaygo.com/login/act_oauth_login.cfm";
export const targetURL = getCookie("targetURL") || "https://distribute.fwaygo.com/login/act_oauth_login.cfm";

console.log("targetURL",targetURL);
function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(';')?.shift();
  }