import { FormEvent, useEffect, useState } from "react";
import TextInput from "../../../components/input/textInput/TextInput";
import AuthPageWrapper from "../components/authPageWrapper/AuthPageWrapper";
import { blue, red } from "../../../utilities/colors";
import SignInButton from "../components/signInButton/SignInButton";
import { forgotPassword } from "../../../auth/auth";
import { Routes } from "../../../navigation/Navigation";

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState<string>("");
  const [attemptResponse, setAttemptResponse] = useState<boolean | null>(null);
  const [issue, setIssue] = useState<string | null>(null);

  useEffect(() => {}, []);

  const handleForgotPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    forgotPassword(email).then((response) => {
      setAttemptResponse(response);
    });
  };

  return (
    <AuthPageWrapper
      header="Enter your email to reset your password."
      footerElement={
        <span
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "14px",
          }}
        >
          {"Return to "}
          <a
            href={Routes.Welcome}
            style={{
              fontFamily: "SFProTextSemibold",
              color: blue,
              textDecoration: "none",
            }}
          >
            {"Log In"}
          </a>
        </span>
      }
    >
      {attemptResponse === null ? (
        <form
          style={{
            display: "contents",
          }}
          onSubmit={handleForgotPassword}
        >
          <TextInput
            state={email}
            setState={setEmail}
            type="email"
            placeholder="Email"
            autoCapitalize={"none"}
            disallowSpaces
            required
          />
          <SignInButton text={"Send Link"} />
        </form>
      ) : (
        <div
          style={{
            width: "300px",
            textAlign: "center",
            marginTop: "30px",
            margin: "10px",
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "rgba(255, 255, 255, 0.8)",
            }}
          >
            {attemptResponse
              ? `An email has been sent to ${email} with instructions for recovering your account. `
              : `There is no account associated with the email address ${email}. `}
          </span>
          <span
            onClick={() => setAttemptResponse(null)}
            style={{
              fontFamily: "SFProTextSemibold",
              color: blue,
              textDecoration: "none",
              cursor: "pointer",
              margin: "6px",
            }}
          >
            {"Try again?"}
          </span>
        </div>
      )}
    </AuthPageWrapper>
  );
}
