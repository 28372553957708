import googlePng from "./google.png";
import google1_5xPng from "./google@1,5x.png";
import google2xPng from "./google@2x.png";
import google3xPng from "./google@3x.png";

const imgProps = {
  src: googlePng,
  srcset: `${google1_5xPng} 1.5x, ${google2xPng} 2x, ${google3xPng} 3x`,
};

export default imgProps;
