import { FormEvent, useState } from "react";
import TextInput, {
  STANDARD_INPUT_MARGIN,
} from "../../../components/input/textInput/TextInput";
import SignInButton from "../components/signInButton/SignInButton";
import { blue, red, white } from "../../../utilities/colors";
import "../auth.scss";
import "./signUpPage.scss";
import AuthPageWrapper from "../components/authPageWrapper/AuthPageWrapper";
import { createUserEmail, fwaygoUser } from "../../../auth/auth";
import { Routes } from "../../../navigation/Navigation";

export default function SignUpPage() {
  const [email, setEmail] = useState<string>(fwaygoUser?.email ?? "");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const setCustomValidityConfirmPassword = (value: string) => {
    if (value !== password) {
      return "Passwords must match";
    }
    return null;
  };

  const handleSignUp = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createUserEmail(email, password).then(({ user, error }) => setError(error));
  };
  return (
    <AuthPageWrapper
      error={error}
      header="Join a growing community of Artists and Fans"
      footerElement={
        <span
          style={{
            color: white,
            fontSize: "14px",
          }}
        >
          {"Have an account? "}
          <a
            href={Routes.Welcome}
            style={{
              fontFamily: "SFProTextSemibold",
              color: blue,
              textDecoration: "none",
            }}
          >
            {"Log in"}
          </a>
        </span>
      }
    >
      <form className="sign-up-page" onSubmit={handleSignUp}>
        <TextInput
          state={email}
          setState={setEmail}
          placeholder="Email"
          type="email"
          disallowSpaces
          required
          style={{
            marginTop: STANDARD_INPUT_MARGIN,
          }}
        />
        <TextInput
          state={password}
          setState={setPassword}
          type="password"
          placeholder="Password"
          style={{
            marginTop: STANDARD_INPUT_MARGIN,
          }}
          autoCapitalize={"none"}
          minLength={6}
          disallowSpaces
          required
        />
        <TextInput
          state={confirmPassword}
          setState={setConfirmPassword}
          type="password"
          placeholder="Confirm Password"
          style={{
            marginTop: STANDARD_INPUT_MARGIN,
          }}
          autoCapitalize={"none"}
          setCustomValidity={setCustomValidityConfirmPassword}
          minLength={6}
          disallowSpaces
          required
        />
        <SignInButton text={"Sign Up"} />
      </form>
    </AuthPageWrapper>
  );
}
