import fwaygoPng from "./fwaygo.png";
import fwaygo1_5xPng from "./fwaygo@1,5x.png";
import fwaygo2xPng from "./fwaygo@2x.png";
import fwaygo3xPng from "./fwaygo@3x.png";

const fwaygoImgProps = {
  src: fwaygoPng,
  srcset: `${fwaygo1_5xPng} 1.5x, ${fwaygo2xPng} 2x, ${fwaygo3xPng} 3x`,
};

export { fwaygoImgProps };
