import "./signInButton.scss";

export type Props = {
  text: string;
};

export default function SignInButton({ text }: Props) {
  return (
    <button type="submit" className="sign-in-button">
      {text}
    </button>
  );
}
