import { ImgProps } from "../../../../assets/pngs/types";
import { grey200, grey300, transparent } from "../../../../utilities/colors";
import "./altSignInButton.scss";

export type Props = {
  imgProps: ImgProps;
  color?: string;
  borderColor?: string;
  alt: string;
  onClick: () => void;
};

const BUTTON_HEIGHT = 46;
const BUTTON_WIDTH = 70;
const MARGIN_HORIZONTAL = 8;
const BORDER_RADIUS = BUTTON_HEIGHT * 0.1;

const ICON_HEIGHT = BUTTON_HEIGHT * 0.6;

export default function AltSignInButton({
  imgProps,
  color,
  borderColor = transparent,
  alt,
  onClick,
}: Props) {
  return (
    <div
      className="alt-sign-in-button"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `${BUTTON_HEIGHT}px`,
        width: `${BUTTON_WIDTH}px`,
        borderRadius: `${BORDER_RADIUS}px`,
        marginLeft: `${MARGIN_HORIZONTAL}px`,
        marginRight: `${MARGIN_HORIZONTAL}px`,
        backgroundColor: color,
        borderColor,
        borderWidth: `1.5px`,
        borderStyle: "solid",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <img style={{ height: `${ICON_HEIGHT}px` }} alt={alt} {...imgProps} />
    </div>
  );
}
