import { Route, Routes as SwitchRoutes } from "react-router-dom";
import WelcomePage from "../pages/auth/welcomePage/WelcomePage";
import SignUpPage from "../pages/auth/signUpPage/SignUpPage";
import AccountDetailsPage from "../pages/auth/accountDetailsPage/AccountDetailsPage";
import ForgotPasswordPage from "../pages/auth/forgotPasswordPage/ForgotPasswordPage";
import useNavigation from "../hooks/useNavigation";
import EmailVerify from "../pages/auth/emailVerify/emailVerify";

export enum Routes {
  Welcome = "/",
  SignUp = "/signup",
  AccountDetails = "/accountDetails",
  ForgotPassword = "/forgotPassword",
  EmailVerify = "/emailVerify"
}

type RouteData = {
  [key: string]: {
    pageName: string;
    Element: () => JSX.Element;
  };
};

export const routeData: RouteData = {
  [Routes.Welcome]: {
    pageName: "Welcome",
    Element: WelcomePage,
  },
  [Routes.SignUp]: {
    pageName: "Sign Up",
    Element: SignUpPage,
  },
  [Routes.AccountDetails]: {
    pageName: "Account Details",
    Element: AccountDetailsPage,
  },
  [Routes.ForgotPassword]: {
    pageName: "Forgot Password",
    Element: ForgotPasswordPage,
  },
  [Routes.EmailVerify]: {
    pageName: "Verify Email",
    Element: EmailVerify,
  },
};

export default function Navigation() {
  useNavigation();
  return (
    <SwitchRoutes>
      {Object.entries(routeData).map(([key, { Element }]) => (
        <Route path={key} element={<Element />} />
      ))}
    </SwitchRoutes>
  );
}
