import { FormEvent, useState } from "react";
import TextInput, {
  STANDARD_INPUT_MARGIN,
} from "../../../components/input/textInput/TextInput";
import SignInButton from "../components/signInButton/SignInButton";
import { blue, red, white } from "../../../utilities/colors";
import "../auth.scss";
import "./accountDetailsPage.scss";
import AuthPageWrapper from "../components/authPageWrapper/AuthPageWrapper";
import {
  getCurrentUser,
  signOutFirebase,
  submitAccountInfo,
} from "../../../auth/auth";
import { usernameRegex } from "../../../utilities/regexes";

export default function AccountDetailsPage() {
  const [first, setFirst] = useState<string>("");
  const [last, setLast] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const setCustomValidityUsername = (
    value: string,
    validity: ValidityState
  ) => {
    if (validity.patternMismatch) {
      return 'Username may contain only letters, numbers, underscores and "&"s ';
    }
    return null;
  };

  const handleSetAccountDetails = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitAccountInfo({
      firstName: first,
      lastName: last,
      birthday: new Date(birthday),
      username,
      city,
      state,
      favoriteArtists: [],
    }).then((response) => {
      const { code, success, message } = response;
      if (!success) {
        setError(`Please try again. Error code: ${code}`);
      }
    });
  };

  return (
    <AuthPageWrapper
      error={error}
      header="Finish setting up your account"
      footerElement={
        <span
          style={{
            color: white,
            fontSize: "14px",
          }}
        >
          {"Have an account? "}
          <a
            href="javascript:;"
            style={{
              fontFamily: "SFProTextSemibold",
              color: blue,
              textDecoration: "none",
            }}
            onClick={signOutFirebase}
          >
            {"Log In"}
          </a>
        </span>
      }
    >
      <form className="account-details-page" onSubmit={handleSetAccountDetails}>
        <span id="email-display">{getCurrentUser()?.email}</span>
        <TextInput
          state={username}
          setState={setUsername}
          placeholder="Username"
          pattern={usernameRegex}
          setCustomValidity={setCustomValidityUsername}
          disallowSpaces
          autoCapitalize={"none"}
          maxLength={30}
          required
        />
        <div className="input-row">
          <TextInput
            state={first}
            setState={setFirst}
            type="name"
            placeholder="First Name"
            maxLength={35}
            style={{
              marginTop: STANDARD_INPUT_MARGIN,
            }}
            required
          />
          <TextInput
            state={last}
            setState={setLast}
            maxLength={35}
            type="name"
            placeholder="Last Name"
            style={{
              marginTop: STANDARD_INPUT_MARGIN,
            }}
            required
          />
        </div>
        <div className="input-row">
          <TextInput
            state={birthday}
            setState={setBirthday}
            placeholder="Birthday"
            type="date"
            style={{
              marginTop: STANDARD_INPUT_MARGIN,
            }}
            required
          />
          <div id="city-state-input">
            <TextInput
              id={"city-input"}
              state={city}
              setState={setCity}
              placeholder="City"
              maxLength={35}
              style={{
                marginTop: STANDARD_INPUT_MARGIN,
              }}
              required
            />
            <TextInput
              id={"state-input"}
              state={state}
              setState={setState}
              placeholder="State"
              optionalLabel
              maxLength={2}
              style={{
                marginTop: STANDARD_INPUT_MARGIN,
              }}
              required
            />
          </div>
        </div>
        <SignInButton text={"Sign Up"} />
      </form>
    </AuthPageWrapper>
  );
}
