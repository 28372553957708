import { ReactNode } from "react";
import { fwaygoImgProps } from "../../../../assets/pngs/fwaygo2";
import "./authPageWrapper.scss";
import goBackPng from "../../../../assets/pngs/goBack.png";

export type Props = {
  header?: string;
  footerElement?: ReactNode;
  children?: ReactNode;
  error?: string | null;
};

export default function AuthPageWrapper({
  header,
  footerElement,
  children,
  error,
}: Props) {
  return (
    <div className="auth-page-wrapper">
      <div id={"form-div"}>
        <a href={"https://fwaygo.com"}>
          <img id={"go-back"} src={goBackPng} alt={""} />
        </a>
        <img id={"fwaygo-logo"} {...fwaygoImgProps} alt={""} />
        {!!header && <span id="header">{header}</span>}
        {!!error && <span id="error">{error}</span>}
        {children}

        {!!footerElement && (
          <div style={{ marginTop: "20px" }}>{footerElement}</div>
        )}
      </div>
    </div>
  );
}
