import { BrowserRouter, Link, Route } from "react-router-dom";
import Navigation from "./navigation/Navigation";
import backgroundPng from "./assets/authBackground/background.png";

export default function App() {
  return (
    <>
      <video id="background-video" autoPlay loop muted poster={backgroundPng}>
        <source
          src={"https://dp6knr9126b6l.cloudfront.net/assets/Website+Banner.mp4"}
          type="video/mp4"
        />
      </video>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </>
  );
}
