import applePng from "./apple.png";
import apple1_5xPng from "./apple@1,5x.png";
import apple2xPng from "./apple@2x.png";
import apple3xPng from "./apple@3x.png";

const imgProps = {
  src: applePng,
  srcset: `${apple1_5xPng} 1.5x, ${apple2xPng} 2x, ${apple3xPng} 3x`,
};

export default imgProps;
