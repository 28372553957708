import { FormEvent, useEffect, useState } from "react";
import TextInput, {
  STANDARD_INPUT_MARGIN,
} from "../../../components/input/textInput/TextInput";
import SignInButton from "../components/signInButton/SignInButton";
import { blue, red, white } from "../../../utilities/colors";
import "../auth.scss";
import "./emailVerify.scss";
import AuthPageWrapper from "../components/authPageWrapper/AuthPageWrapper";
import {
  getCurrentUser,
  setAndVerifyEmail,
  signOutFirebase,
  submitAccountInfo,
} from "../../../auth/auth";
import { usernameRegex } from "../../../utilities/regexes";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../auth/firebase";

export default function EmailVerify() {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const setCustomValidityUsername = (
    value: string,
    validity: ValidityState
  ) => {
    if (validity.patternMismatch) {
      return 'Username may contain only letters, numbers, underscores and "&"s ';
    }
    return null;
  };

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user?.email && !email) {
        setEmail(user?.email);
      }})
  }, [])



  const handleSetAccountDetails = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAndVerifyEmail(email).then((v)=>{
      if (v === 1) {
        setError("Email updated. Check your inbox for a verification link.")
      } else if ( v === 2){
        setError("Check your inbox for a verification link.")
      } else if (v?.code === "auth/email-already-in-use"){
        setError("Another account has already claimed this email")
      } else if (v?.code === "auth/requires-recent-login"){
        alert("Your login has expired. Please re-authenticate and retry verfying your email.")
        signOutFirebase();
      }
    });
  };

  return (
    <AuthPageWrapper
      error={error}
      header="Enter and verify your email"
      footerElement={
        <span
          style={{
            color: white,
            fontSize: "14px",
          }}
        >
          {"Have an account? "}
          <a
            href="javascript:;"
            style={{
              fontFamily: "SFProTextSemibold",
              color: blue,
              textDecoration: "none",
            }}
            onClick={signOutFirebase}
          >
            {"Log In"}
          </a>
        </span>
      }
    >
      <form className="account-details-page" onSubmit={handleSetAccountDetails}>      
        <div className="input-row">
          <TextInput
            state={email}
            setState={setEmail}
            type="name"
            placeholder="email"
            maxLength={35}
            style={{
              marginTop: STANDARD_INPUT_MARGIN,
            }}
            required
          />
        </div>
        
        <SignInButton text={"Verify"} />
      </form>
    </AuthPageWrapper>
  );
}
