import { FormEvent, useEffect, useState } from "react";
import TextInput, {
  STANDARD_INPUT_MARGIN,
} from "../../../components/input/textInput/TextInput";
import AuthPageWrapper from "../components/authPageWrapper/AuthPageWrapper";
import { sfProTextSemibold } from "../../../utilities/fonts";
import AltSignInButton, {
  Props as AltSignInButtonProps,
} from "../components/altSignInButton/AltSignInButton";
import { black, blue, red, white } from "../../../utilities/colors";
import {
  googleImgProps,
  facebookImgProps,
  appleImgProps,
} from "../../../assets/pngs/signInLogos";
import SignInButton from "../components/signInButton/SignInButton";
import {
  fwaygoUser,
  signInApple,
  signInEmail,
  signInFacebook,
  signInGoogle,
} from "../../../auth/auth";
import { Routes } from "../../../navigation/Navigation";

const facebookBlue = "#3875EA";

const altSignInButtonData: AltSignInButtonProps[] = [
  {
    color: white,
    imgProps: googleImgProps,
    alt: "google",
    onClick: signInGoogle,
  },
  // {
  //   color: facebookBlue,
  //   imgProps: facebookImgProps,
  //   alt: "facebook",
  //   onClick: signInFacebook,
  // },
  {
    color: black,
    imgProps: appleImgProps,
    alt: "apple",
    onClick: signInApple,
  },
];

export default function WelcomePage() {
  const [email, setEmail] = useState<string>(fwaygoUser?.email ?? "");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signInEmail(email, password).then(({ user, error }) => {
      if (!user) {
        setPassword("");
        setError("Incorrect email or password. Please try again.");
      }
    });
  };
  return (
    <AuthPageWrapper
      error={error}
      footerElement={
        <span
          style={{
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "14px",
          }}
        >
          {"Don't have an account? "}
          <a
            href={Routes.SignUp}
            style={{
              fontFamily: "SFProTextSemibold",
              color: blue,
              textDecoration: "none",
            }}
          >
            {"Sign up"}
          </a>
        </span>
      }
    >
      <form style={{ display: "contents" }} onSubmit={handleLogin}>
        <TextInput
          state={email}
          setState={setEmail}
          type="email"
          placeholder="Email"
          disallowSpaces
          required
        />
        <TextInput
          state={password}
          setState={setPassword}
          type="password"
          placeholder="Password"
          style={{ marginTop: STANDARD_INPUT_MARGIN }}
          autoCapitalize={"none"}
          minLength={6}
          disallowSpaces
          required
        />
        <div
          style={{
            width: "340px",
            maxWidth: "90%",
            marginTop: "12px",
            paddingLeft: "2px",
          }}
        >
          <a
            href={Routes.ForgotPassword}
            style={{
              color: white,
              textDecoration: "none",
              fontSize: "12px",
            }}
          >
            Forgot your password?
          </a>
        </div>
        <SignInButton text={"Sign In"} />
      </form>
      <span
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          fontSize: "14px",
          fontFamily: sfProTextSemibold,
          color: white,
        }}
      >
        or continue with
      </span>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {altSignInButtonData.map(AltSignInButton)}
      </div>
    </AuthPageWrapper>
  );
}
