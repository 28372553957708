import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  User,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
  updateEmail,
  getAuth,
} from "firebase/auth";

import { auth } from "./firebase";

import {} from "firebase/auth";
import { ENDPOINT, targetURL } from "../constants";
import { Routes } from "../navigation/Navigation";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export var fwaygoUser: UserType = {};

export async function getIdToken(): Promise<string> {
  const cu = getCurrentUser();
  if (cu) return cu.getIdToken();
  throw "current user null";
}

export function getCurrentUser(): User | null {
  return auth.currentUser;
}

export function redirect(target: string) {
  console.log("redirect", target);
  if (window.location.pathname !== target) document.location.href = target;
}

export async function executeQuery(query: string): Promise<any | null> {
  const id_token = await getIdToken(); //This is the access_token
  console.log("id_token", id_token);
  return fetch(`${ENDPOINT}/graphql`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: id_token,
    },
    body: JSON.stringify({ query }),
  })
    .then(async (res) => {
      console.log("[executeQuery] result", res.ok, res.body, res.status, query);
      if (!res.ok) return null;
      return await res.json().catch();
    })
    .catch((err) => {
      console.log("[executeQuery] error", err);
      throw err;
    });
}

async function getUser(): Promise<UserType> {
  const query = `
    {
      user {
        id
        username
        firstName
        lastName
        email
      }
    }
    `;
  const response = await executeQuery(query);
  return response?.data?.user;
}

export async function submitAccountInfo(
  userInfo: SignUpParams
): Promise<{ code: number; success: boolean; message: string }> {
  const {
    username,
    firstName,
    lastName,
    birthday,
    gender,
    city,
    state,
    country,
    favoriteArtists,
  } = userInfo;

  const day = birthday ? birthday.getUTCDate() : "";
  const month = birthday ? birthday.getUTCMonth() + 1 : "";
  const year = birthday ? birthday.getUTCFullYear() : "";
  const formattedFavoriteArtists = favoriteArtists?.map(
    (artist) => `"${artist}"`
  );
  console.log(formattedFavoriteArtists, "formattedFavoriteArtists");
  const query = `
        mutation {
          updateUser (
             input : {
               ${username ? `username: "${username}",` : ""}
               ${firstName ? `firstName: "${firstName}",` : ""}
               ${lastName ? `lastName: "${lastName}",` : ""}
               ${
                 day && month && year
                   ? `birthday: {day: ${day}, month: ${month}, year: ${year}},`
                   : ""
               }
               ${
                 formattedFavoriteArtists
                   ? `favoriteArtists: [${formattedFavoriteArtists}]`
                   : ""
               }
            }
          )
          {code success message}
        }
    `;
  console.log(query, "query");
  const response = await executeQuery(query);
  console.log(response, "response");
  const { code, success, message } = response?.data?.updateUser;
  if (success) {
    handleUserUpdate();
  }
  return { code, success, message };
}


export async function setAndVerifyEmail(email: string){
  const redirectInfo = {url: "https://login.fwaygo.com/"};
  if (!auth.currentUser || !email) return false;
  if (auth.currentUser.email !== email) {
    // verifyBeforeUpdateEmail(auth.currentUser, email, redirectInfo).then(() => {
    return await verifyBeforeUpdateEmail(auth.currentUser, email, redirectInfo).then(() => {
      // handleUserUpdate();
      console.log("setAndVerifyEmail", "verifyBeforeUpdateEmail", email)
      return 1
    }).catch((e)=>{
      alert(JSON.stringify(e))
      return e
    });
  } else {
    await sendEmailVerification(auth.currentUser, redirectInfo).then(() => {
      // handleUserUpdate();
      console.log("setAndVerifyEmail", "sendEmailVerification", email)
      return 2
    }).catch((e)=>{
      alert(JSON.stringify(e))
      return e
    });
  }

  
}

async function handleUserUpdate() {
  const thisUser = await getUser();
  console.log("EVEARA getUser", thisUser);
  fwaygoUser = thisUser;
  fwaygoUser.email = thisUser?.email;
  if (auth.currentUser?.emailVerified == false) {
    redirect(Routes.EmailVerify);
    // // verifyBeforeUpdateEmail(user, "eliwstewart11@gmail.com", {url: 'http://localhost:3000/'}).then(() => {
    //   updateEmail(auth.currentUser, "123@123.com").then(() => {
    //     alert("sent")
    //   }).catch((e)=>{
    //     alert(JSON.stringify(e))
    //   });
    return
  }
  if (fwaygoUser.firstName && fwaygoUser.lastName && fwaygoUser.username) {
    //Login complete. Navigate to Eveara
    // await ping();
    const { auth_code, redirect_uri } = (await getOAuthCode()) ?? {};
    console.log("EVEARA getOAuthCode", auth_code, redirect_uri);
    if (!auth_code) {
      alert("getOAuthCode failed");
      return;
    }

    // //TESTING
    // const response = await getTokenWithAuthCode(auth_code);
    // console.log("EVEARA getTokenWithAuthCode", response);
    // const response2 = await getAccessTokenWithRefreshToken(response);
    // console.log("EVEARA getAccessTokenWithRefreshToken", auth_code, response2);
    // //END TEST

    console.log("targetURL", targetURL);
    var url = new URL(targetURL);
    url.searchParams.append("code", auth_code);
    console.log("EVEARA redirecting to ", url.href);
    // alert(`Redirecting: ${JSON.stringify(fwaygoUser)}`);
    redirect(url.href);
  } else {
    //Navigate to account details page
    redirect(Routes.AccountDetails);
  }
}

onAuthStateChanged(auth, (user) => {
  if (user) {
    const queryString = window.location.search;
    console.log("onAuthStateChanged", user);
    const urlParams = new URLSearchParams(queryString);

    const signout = urlParams.get("signout");

    // sendEmailVerification(user)
    // .then(() => {
    //   alert("sent")
    // }).catch((e)=>{
    //   alert(JSON.stringify(e))
    // });


    if (signout) {
      signOutFirebase().then(() => {
        const urlObj = new URL(window.location.href);

        urlObj.search = "";

        const result = urlObj.toString();
        redirect(result);
      });
    } else {

      handleUserUpdate();
    }
  } else {
    //Navigate to welcome page
    if (
      window.location.pathname !== Routes.SignUp &&
      window.location.pathname !== Routes.ForgotPassword
    )
      redirect(Routes.Welcome);
  }
});

async function updateAuth(func: () => Promise<UserCredential>, name?: string) {
  return await func()
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(`${name ?? "login"} success`, user);
      return {
        user,
        error: null,
      };
    })
    .catch((error) => {
      const errorCode = error.code;
      let errorMessage = error.message;
      console.log(`${name ?? "login"} error`, error.code);
      if (error.code === "auth/email-already-in-use")
        errorMessage = "Account with this email already exists. Please log in";

      return {
        user: null,
        error: errorMessage,
      };
    });
}

export async function createUserEmail(email: string, password: string) {
  fwaygoUser.email = email;
  const func = () => createUserWithEmailAndPassword(auth, email, password);
  return updateAuth(func, "createUserWithEmailAndPassword");
}

export async function signInEmail(email: string, password: string) {
  fwaygoUser.email = email;
  const func = () => signInWithEmailAndPassword(auth, email, password);
  return updateAuth(func, "signInWithEmailAndPassword");
}

export async function forgotPassword(email: string) {
  return await sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("forgotPassword sent to", email);
      // Password reset email sent!
      // ..
      return true;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("forgotPassword error", email, errorCode, errorMessage);

      // ..
      return false;
    });
}

export async function signOutFirebase() {
  console.log("signOut");
  signOut(auth);
}

export function signInGoogle() {
  signInWithPopup(auth, googleProvider).catch(() => {});
}

export function signInFacebook() {
  signInWithPopup(auth, facebookProvider).catch(() => {});
}

export function signInApple() {
  signInWithPopup(auth, appleProvider).catch((e) => {
    console.log(e);
  });
}

//https://backend.fwaygoapp.com/oauth/token/new?client_id=iclimJczc3ScJlbdGwKwSIcs&client_secret=iscrtfuHQ3HUCyr3ttGbYW7mEKx6eyrOQIn

export async function getOAuthCode(): Promise<AuthCodeResponse | null> {
  const id_token = await getIdToken();
  console.log("id_token", id_token);
  const url = `${ENDPOINT}/oauth/get_code/?client_id=iclimJczc3ScJlbdGwKwSIcs&client_secret=iscrtfuHQ3HUCyr3ttGbYW7mEKx6eyrOQIn&scope=read&response_type=code&redirect_uri=${targetURL}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: id_token,
    },
  })
    .then(async (res) => {
      console.log("[executeQuery] result", res.ok, res.body, res.status);
      if (!res.ok) return null;
      return await res.json().catch();
    })
    .catch((err) => {
      console.log("[executeQuery] error", url, err);
      throw err;
    });
}

export async function getTokenWithAuthCode(code: string): Promise<any | null> {
  // console.log("[executeQuery] request", endpoint ?? ENDPOINT, query);
  const id_token = await getIdToken();
  console.log("id_token", id_token);
  return fetch(
    `${ENDPOINT}/oauth/token?client_id=iclimJczc3ScJlbdGwKwSIcs&client_secret=iscrtfuHQ3HUCyr3ttGbYW7mEKx6eyrOQIn&code=${code}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: id_token,
      },
    }
  )
    .then(async (res) => {
      console.log(
        "[executeQuery] result",
        `${ENDPOINT}/oauth/token?client_id=iclimJczc3ScJlbdGwKwSIcs&client_secret=iscrtfuHQ3HUCyr3ttGbYW7mEKx6eyrOQIn&code=${code}`,
        res.ok,
        res.body,
        res.status
      );
      if (!res.ok) return null;
      return await res.json().catch();
    })
    .catch((err) => {
      console.log("[executeQuery] error", err);
      throw err;
    });
}

export async function getAccessTokenWithRefreshToken(
  refreshToken: string
): Promise<any | null> {
  // console.log("[executeQuery] request", endpoint ?? ENDPOINT, query);
  return fetch(
    `${ENDPOINT}/oauth/token/new?client_id=iclimJczc3ScJlbdGwKwSIcs&client_secret=iscrtfuHQ3HUCyr3ttGbYW7mEKx6eyrOQIn`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: refreshToken,
      },
    }
  )
    .then(async (res) => {
      console.log(
        "[executeQuery] result",
        `${ENDPOINT}/oauth/token/new?client_id=iclimJczc3ScJlbdGwKwSIcs&client_secret=iscrtfuHQ3HUCyr3ttGbYW7mEKx6eyrOQIn`,
        res.ok,
        res.body,
        res.status
      );
      if (!res.ok) return null;
      return await res.json().catch();
    })
    .catch((err) => {
      console.log("[executeQuery] error", err);
      throw err;
    });
}

export async function ping(): Promise<AuthCodeResponse | null> {
  const url = `${ENDPOINT}/healthCheck`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  })
    .then(async (res) => {
      console.log("[ping] result", res.ok, res.body, res.status);
      if (!res.ok) return null;
      return await res.json().catch();
    })
    .catch((err) => {
      console.log("[ping] error", url, err);
      throw err;
    });
}
