import { CSSProperties, useRef } from "react";
import Input, { Props as InputProps } from "../Input";
import "./textInput.scss";

export type Props = InputProps & {
  label?: string;
  inputStyle?: CSSProperties;
  optionalLabel?: true;
};

export const STANDARD_INPUT_MARGIN = 10;

export default function TextInput({
  id,
  label,
  style,
  inputStyle,
  optionalLabel,
  ...rest
}: Props) {
  const { state, placeholder } = rest;
  const ref = useRef<HTMLInputElement | null>(null);

  return (
    <div id={id} className="text-input" style={style}>
      <span
        id="label"
        style={{ visibility: !!state?.length ? "visible" : "hidden" }}
      >
        {label ?? placeholder}
      </span>
      <Input ref={ref} {...rest} style={inputStyle} />
      {!!optionalLabel && <span id="optional">optional</span>}
    </div>
  );
}
