import facebookPng from "./facebook.png";
import facebook1_5xPng from "./facebook@1,5x.png";
import facebook2xPng from "./facebook@2x.png";
import facebook3xPng from "./facebook@3x.png";

const imgProps = {
  src: facebookPng,
  srcset: `${facebook1_5xPng} 1.5x, ${facebook2xPng} 2x, ${facebook3xPng} 3x`,
};

export default imgProps;
