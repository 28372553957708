import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { routeData } from "../navigation/Navigation";
import { signOutFirebase } from "../auth/auth";

export default function useNavigation() {
  const location = useLocation();
  useEffect(() => {
    document.title = `FWAYGO | ${routeData[location.pathname].pageName}`;

    // const params = new URLSearchParams(location?.search);
    // if (params?.get("signout") === "true") signOutFirebase();
  }, [location]);

  return {};
}
