import {
  ChangeEvent,
  DetailedHTMLProps,
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useRef,
} from "react";

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type CustomValidityFunction = (
  value: string,
  validity: ValidityState
) => string | null;

export type Props = InputProps & {
  placeholder: string;
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  setCustomValidity?: CustomValidityFunction;
  disallowSpaces?: boolean;
};

export default function Input({
  state,
  setState,
  setCustomValidity,
  disallowSpaces,
  style,
  onChange,
  ...rest
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleSetCustomValidity = (
    value: string,
    validity: ValidityState,
    validationFunction: CustomValidityFunction
  ) => {
    const result = validationFunction(value, validity);
    if (result) inputRef.current?.setCustomValidity(result);
    else inputRef.current?.setCustomValidity("");
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
    inputRef.current?.setCustomValidity("");
    let value = event.target.value;
    const validity = event.target.validity;
    if (setCustomValidity)
      handleSetCustomValidity(value, validity, setCustomValidity);
    if (disallowSpaces) value = value.replace(/\s+/g, "");
    setState(value);
  };

  // const onSubmit = () => {
  //   if (!inputRef.current) return;
  //   const value = inputRef.current.value;
  //   const validity = inputRef.current.validity;
  //   if (setCustomValidity)
  //     handleSetCustomValidity(value, validity, setCustomValidity);
  // };

  return (
    <input
      style={{
        ...{
          outline: "none",
        },
        ...style,
      }}
      ref={inputRef}
      value={state}
      onChange={handleChange}
      // onSubmit={onSubmit}
      {...rest}
    />
  );
}
