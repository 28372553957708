const white = "#FFFFFF";
const black = "#000000";
const transparent = "transparent";

const grey100 = "#f3f4f6";
const grey200 = "#e5e7eb";
const grey300 = "#d1d5db";
const grey400 = "#9ca3af";
const grey500 = "#6b7280";
const grey600 = "#4b5563";
const grey700 = "#374151";
const grey800 = "#1f2937";
const grey900 = "#111827";

const red = "#FD2B3B";
const blue = "#3A86FF";
const pink = "#f23897";

export {
  white,
  black,
  transparent,
  grey100,
  grey200,
  grey300,
  grey400,
  grey500,
  grey600,
  grey700,
  grey800,
  grey900,
  red,
  blue,
  pink,
};
